import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const CanteenMasterSetup = Loadable(lazy(() => import('views/canteen/masterSetup/index')));
const CanteenOwnerDashboard = Loadable(lazy(() => import('../views/canteen/canteenOwner/index')));
const CategoryList = Loadable(lazy(() => import('views/canteen/addRestorents/CategoryList')));
const FoodOrders = Loadable(lazy(() => import('../views/canteen/orders')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_RESTAURANT_OWNER'];

const CanteenRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/canteen/setup',
            element: <CanteenMasterSetup />
        },
        {
            path: '/canteen/dashboard',
            element: <CanteenOwnerDashboard />
        },
        {
            path: '/category/list/:id',
            element: <CategoryList />
        },
        {
            path: '/canteen/orders',
            element: <FoodOrders />
        }
    ]
};
export default CanteenRoutes;
