import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
// import StaffLibraryDashboard from 'views/Library/dashboards/staff';
// import TeacherDashboard from 'views/teacher/dashboard/TeacherDashboard';

// const Dashboard = Loadable(lazy(() => import('views/teacher/dashboard/DashboardOld')));
const TeacherDashboard = Loadable(lazy(() => import('views/teacher/dashboard/new-dashboard')));
const TeacherProfile = Loadable(lazy(() => import('views/teacher/profile')));
const TeachingResources = Loadable(lazy(() => import('views/teacher/teaching-resourses')));
const LearningResources = Loadable(lazy(() => import('views/teacher/learning-resources')));
const LibraryDashboard = Loadable(lazy(() => import('views/Library/dashboards/staff')));
// const AttendedClasses = Loadable(lazy(() => import('views/teacher/dashboard/new-dashboard/AttendedClasses')));
// const AbsentClasses = Loadable(lazy(() => import('views/teacher/dashboard/new-dashboard/AbsentClasses')));
const MyLeave = Loadable(lazy(() => import('views/teacher/leave/my-leave/MyLeave')));
const ApproveLeave = Loadable(lazy(() => import('views/teacher/leave/LeaveApprove/LeaveApproval')));
const TeacherStudentList = Loadable(lazy(() => import('views/teacher/dashboard/StudentList')));
const HodDashboard = Loadable(lazy(() => import('views/hod/HodDashboard')));
const AnnouncementApproval = Loadable(lazy(() => import('views/hod/AnnouncementApproval')));
const LessonPlanApprovalRequested = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanRequestedList')));
const AccountantDashBoard = Loadable(lazy(() => import('views/dashboard/accountent/Dashboard')));
const RegistrarDashBoard = Loadable(lazy(() => import('views/registrardashboard/RegistrarDashboard')));

const LeaveReport = Loadable(lazy(() => import('views/teacher/leave/LeaveReport')));

const LeaveListByType = Loadable(lazy(() => import('views/teacher/dashboard/LeaveListbyType')));
const DeanDashboard = Loadable(lazy(() => import('views/dashboard/dean/DeanDashboard')));
const DUCDashboard = Loadable(lazy(() => import('views/dashboard/duc/DUCDashboard')));

const EmployeePaySlips = Loadable(lazy(() => import('../views/teacher/payslips')));
const TeacherUploadQuestionPaper = Loadable(lazy(() => import('views/questionBank/index')));

const roles = ['ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN'];

const TeacherRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/teacher/dashboard',
        //     element: <Dashboard />
        // },
        {
            path: '/teacher/dashboard',
            element: <TeacherDashboard />
        },
        {
            path: '/teacher/library/dashboard',
            element: <LibraryDashboard />
        },
        {
            path: '/teacher-profile',
            element: <TeacherProfile />
        },
        {
            path: '/teaching-resources',
            element: <TeachingResources />
        },
        {
            path: '/teacher/upload-question-paper',
            element: <TeacherUploadQuestionPaper />
        },
        {
            path: '/learning-resources',
            element: <LearningResources />
        },
        // {
        //     path: '/dashboard/staff/attendance/absent',
        //     element: <AbsentClasses />
        // },
        {
            path: '/teacher/leaves',
            element: <MyLeave />
        },
        {
            path: '/teacher/leaves/approve',
            element: <ApproveLeave />
        },
        {
            path: '/teacher/studentlist',
            element: <TeacherStudentList />
        },
        {
            path: '/leave/report',
            element: <LeaveReport />
        },
        {
            path: '/hod/dashboard',
            element: <HodDashboard />
        },
        {
            path: '/dean/dashboard',
            element: <DeanDashboard />
        },
        {
            path: '/duc/dashboard',
            element: <DUCDashboard />
        },
        {
            path: '/accountant/dashboard',
            element: <AccountantDashBoard />
        },
        {
            path: '/announcement/approval',
            element: <AnnouncementApproval />
        },
        {
            path: '/lesson/plan/approval',
            element: <LessonPlanApprovalRequested />
        },
        {
            path: '/registrar/dashboard',
            element: <RegistrarDashBoard />
        },
        {
            path: '/leavelist/bytype',
            element: <LeaveListByType />
        },
        {
            path: '/employee/payslips',
            element: <EmployeePaySlips />
        }
    ]
};
export default TeacherRoutes;
