import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const HiringProcess = Loadable(lazy(() => import('views/application/human-resource/hiring-process/index')));
const EditHiringProcess = Loadable(lazy(() => import('views/application/human-resource/hiring-process/EditHiringProcess')));
const Skills = Loadable(lazy(() => import('views/application/human-resource/skills')));
const JobPostRequest = Loadable(lazy(() => import('views/application/human-resource/job-post-request')));
const Edit = Loadable(lazy(() => import('views/application/human-resource/job-post-request/Edit')));
const JobPostCreate = Loadable(lazy(() => import('views/application/human-resource/job-post-create')));
const JobPostEdit = Loadable(lazy(() => import('views/application/human-resource/job-post-create/editjobpost/EditJobPost')));
const JobPostapproval = Loadable(lazy(() => import('views/application/human-resource/job-post-approval')));
const JobApplicationList = Loadable(lazy(() => import('views/application/human-resource/applicationlist/TeacherJobApplicationList')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const HumanResourceRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/hiring-process',
            element: <HiringProcess />
        },
        {
            path: '/update/hiring-process/:id',
            element: <EditHiringProcess />
        },
        {
            path: '/skills',
            element: <Skills />
        },
        {
            path: '/job-post-request',
            element: <JobPostRequest />
        },
        {
            path: '/job-post-request/edit/:id',
            element: <Edit />
        },
        {
            path: '/job-post-create',
            element: <JobPostCreate />
        },
        {
            path: '/job-post-create/edit/:id',
            element: <JobPostEdit />
        },
        {
            path: '/job-post-approval',
            element: <JobPostapproval />
        },
        {
            path: '/job/application/list',
            element: <JobApplicationList />
        }
    ]
};
export default HumanResourceRoutes;
